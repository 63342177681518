import "@hotwired/turbo-rails"

// import "./pwa_install";
import "./controllers";

// import "chartkick/chart.js";
// import { Chart } from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";
// import { SankeyController, Flow } from "chartjs-chart-sankey";

// Chart.register(SankeyController, Flow);

// window.Chart = Chart;
// window.ChartDataLabels = ChartDataLabels;

import anime from 'animejs/lib/anime.es.js';
window.anime = anime;

import AOS from "aos";
window.AOS = AOS;

AOS.init();

import Choices from "choices.js";
window.Choices = Choices;

// https://echarts.apache.org/handbook/en/basics/import#shrinking-bundle-size
import * as echarts from 'echarts/core';
import { LineChart, LinesChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  GridComponent,
} from 'echarts/components';
echarts.use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  GridComponent,
  LineChart,
  LinesChart
]);

window.echarts = echarts;
