import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    document.querySelector("main").style.filter = "blur(2px)";
    document.documentElement.style.overflow = "hidden";
  }

  // hide modal
  // action: "turbo-dialog#hideModal"
  hideModal() {
    document.querySelector("main").style.filter = "";
    document.querySelector("dialog").removeAttribute("src");
    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent click
    document.documentElement.style.overflow = "auto";
    document.querySelector("dialog").remove();
  }

  hideTarget() {
    this.element.removeAttribute("src")
    this.element.remove()
    document.querySelector("main").style.filter = "";
    document.documentElement.style.overflow = "auto";
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-dialog#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideTarget()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-dialog#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideTarget()
    }
  }
}
